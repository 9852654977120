import { loadVehicleData } from '@utilities/populatePriorData/vehicles/populateVehicleData.js';
import { setRequiredForms } from '@utilities/helpers/setRequiredForms';
import _ from 'lodash';
import { dashboard as defaultDashboard } from '@utilities/constants/dashboard';
import { calculateDashboardSummary } from '@utilities/helpers/calculateDashboardSummary.js';

let priorYear = null;

export async function loadOrganizerDetails(organizerId, dispatch, ACTION, api, updateUploadList, setEntityFormState, clientNumber, categories) {
  console.log('Loading organizer details...', organizerId, clientNumber);
  try {
    if (!clientNumber || clientNumber === '') throw new Error("Client number not found");
    const { data: { results: organizers } } = await api.get(`/organizers?filter=client.number eq ${clientNumber}`);

    const organizer = organizers.find(org => org.id === organizerId);
    if (!organizer) throw new Error("Organizer not found");

    // Prepare prior year client data
    const priorYearByClient = organizers.reduce((acc, org) => {
      if (!acc[ org.client?.number ]) {
        acc[ org.client?.number ] = [ { name: org.year, value: org.year, id: org.id } ];
      } else {
        acc[ org.client?.number ].push({ name: org.year, value: org.year, id: org.id });
      }
      return acc;
    }, {});

    // Get prior year organizer
    const priorOrganizer = organizers.find(obj =>
      obj.client.number === organizer?.client?.number &&
      obj.year === organizer.year - 1 &&
      obj.id !== organizer.id
    );

    if (priorYear === null) {
      priorYear = priorOrganizer?.year || false;
    }

    dispatch(ACTION.setPriorYearDetails({
      priorYear: priorOrganizer?.year || false,
      priorOrganizerId: priorOrganizer?.id || undefined,
      priorYearLookup: priorYearByClient[ organizer?.client?.number ]
    }));

    // Fetch full organizer details
    const { data: organizerDoc } = await api.get(`/organizers/${organizerId}`);
    if (!organizerDoc) return;
    dispatch(ACTION.setSelectedOrganizer(organizerDoc));

    const {
      id,
      forms,
      locked,
      client,
      status,
      year,
      entryExperience,
      firstLogIn,
      taxpayer,
      spouse,
      bannerDismissed,
      taxReturnStatus,
      isTermsAgreed,
      dashboardVersion
    } = organizerDoc;

    // Dispatch organizer-specific details
    dispatch(ACTION.setBannerDismissed(bannerDismissed));
    dispatch(ACTION.setTaxReturnStatus(taxReturnStatus));
    dispatch(ACTION.setTaxpayerEmail(taxpayer?.email || ''));
    dispatch(ACTION.setSpouseEmail(spouse?.email || ''));
    dispatch(ACTION.setIsTermsAgreed(isTermsAgreed));
    dispatch(ACTION.setOrganizerId(id));

    // Fetch related dashboard, notes, and prior year data
    const [ dashboardResponse, notesResponse, priorYearResponse ] = await Promise.all([
      api.get(`/organizers/${id}/dashboard`).catch(err => console.log('Dashboard error: ', err.message)),
      api.get(`/organizers/${id}/notes`).catch(err => console.log('Notes error: ', err.message)),
      api.get(`/organizers/${id}/prior`).catch(err => console.log('Prior year error: ', err.message)),
      updateUploadList(id),
    ]);

    const dashboard = categories ? categories : dashboardResponse?.data?.dashboard || null;
    const priorYearData = priorYearResponse?.data?.data?.taxData?.priorYear || null;
    const notes = notesResponse?.data?.results || [];

    dispatch(ACTION.setFormNotes(notes));
    dispatch(ACTION.setPriorYearData(priorYearData));
    dispatch(ACTION.setDashboardId(dashboardResponse?.data?.id));
    // Handle dashboard creation or update
    let latestDashboard = dashboard;
    if (dashboard) {
      dispatch(ACTION.setLastSaveFormTime(dashboard?.[ 0 ]?.cards?.[ 0 ]?.lastFormSavedOn || null));
      dispatch(ACTION.setDashboard(dashboard));
    } else {
      const dashCopy = setRequiredForms(priorYearData || [], _.cloneDeep(dashboard || defaultDashboard));
      latestDashboard = _.cloneDeep(dashCopy);
      const { summary } = await calculateDashboardSummary(dashboardResponse?.data?.id, dashCopy, organizer);

      await Promise.all([
        api.put(`/organizers/${id}`, { lastUserActivityOn: new Date(), dashboardSummary: summary })
          .catch(err => console.log("Unable to update organizer:\n", err.message)),
        api.put(`/organizers/${id}/dashboard/${dashboardResponse?.data?.id}`, { dashboard: dashCopy })
          .catch(err => console.log('Unable to update dashboard:\n', err.message)),
      ]);

      dispatch(ACTION.setDashboard(dashCopy));
    }

    // Handle first log-in recording
    if (!firstLogIn) {
      await api.put(`/organizers/${id}`, { firstLogIn: new Date() });
    }

    // Process forms and vehicle data
    const formKeys = [];
    let hasVehicleData = false;
    forms?.forEach(form => {
      if (!formKeys.includes(form.key)) {
        dispatch(ACTION.setForm(form.key, form.data));
        hasVehicleData = hasVehicleData || form.key.startsWith("VEHICLE");
        formKeys.push(form.key);
      }
    });

    if (priorYearData && !hasVehicleData) {
      const vehicleFormData = loadVehicleData(priorYearData, year);
      if (vehicleFormData) {
        Object.entries(vehicleFormData).forEach(([ key, value ]) => {
          setEntityFormState(key, value, id, latestDashboard);
        });
      }
    }

    // Dispatch organizer metadata
    dispatch(ACTION.setActiveReturn({
      displayName: client.name,
      clientNumber: client.number,
      formStatus: status,
      currentYear: year
    }));

    dispatch(ACTION.setOrganizerId(id));
    dispatch(ACTION.setYear(year));
    if (year === priorYear){
      dispatch(ACTION.setLockForms(true));
      dispatch(ACTION.setUploadList([]));
    }
    else{
      dispatch(ACTION.setLockForms(locked));
    }
    dispatch(ACTION.setIsSaveSuccess(!!id));
    dispatch(ACTION.setCompletedEntryStep(!!entryExperience?.completedStep));

    return { organizer, latestDashboard };
  } catch (error) {
    console.error("Error loading organizer details:", error.message);
    throw error;
  }
}
