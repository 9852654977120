import { GridCell } from 'react-md';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const GRID_COLUMM_EMAIL_CHARACTERS_LIMIT = 25;

const MAX_EMAIL_CHARACTER_LIMIT = 50;

// this components' width adjust dynamically based on the content
const StyledNoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

function GridCellEmailTooltip({ user, propertyName, index, colSpan }) {

    const value = user[propertyName];
    let gridCellValue = <></>;
    let gridCellClassName = '';

    if (value.length <= GRID_COLUMM_EMAIL_CHARACTERS_LIMIT) {
        gridCellValue = value;
    } else {
        const isEmailExceedCharacterLimit = value.length > MAX_EMAIL_CHARACTER_LIMIT;
        gridCellClassName = `email-tooltip-${isEmailExceedCharacterLimit ? 'normal' : 'no-max-width'}`;

        const props = {
            title: <div className={'email-checklist-hover-tooltip'}>{value}</div>,
            placement: 'top',
            className: `gridcell-email-tooltip`,
            arrow: true
        };

        const tooltipContent = `${value.substring(0, GRID_COLUMM_EMAIL_CHARACTERS_LIMIT)}...`;

        gridCellValue = isEmailExceedCharacterLimit
            ? <Tooltip {...props}> {tooltipContent} </Tooltip>
            : <StyledNoMaxWidthTooltip {...props}> {tooltipContent} </StyledNoMaxWidthTooltip>;
    }

    return (
        <GridCell
            colSpan={colSpan}
            className={`email-checklist-cell grid-email-cell ${gridCellClassName}`}
            data-testid={`email-checklist-${propertyName}-${index}`}
        >
            {gridCellValue}
        </GridCell>
    );
}

export default GridCellEmailTooltip;